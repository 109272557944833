<template>
    <el-dialog
        width="600px"
        top="40px"
        :title="$t('keys.edit_key')"
        :visible.sync="modalEditKey"
        :close-on-click-modal="false"
        :before-close="() => !$wait.is('*') && $emit('close')"
        @closed="$emit('closed')"
    >
        <div v-if="selectedKey.image_src" class="flex justify-center mb-5 px-12">
            <div class="relative">
                <el-button class="absolute right-0 top-0" type="danger" size="mini" @click="removeExistingImage">
                    <fa-icon :icon="['fas', 'trash']" />
                </el-button>
                <img :src="file" class="rounded-lg w-72 h-72 border border-gray-600">
            </div>
        </div>
        <div v-else class="flex justify-center mb-5 px-12">
            <el-upload
                ref="upload"
                action="#"
                :class="{ 'hide-new-file': file }"
                list-type="picture-card"
                :on-change="imageHandleChange"
                :on-remove="imageHandleRemove"
                :multiple="false"
                accept=".jpg,.png,.jpeg"
                :limit="1"
                :auto-upload="false"
            >
                <i slot="default" class="el-icon-camera" />
                <!-- <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file" alt="">
                    <span class="el-upload-list__item-actions">
                        <span
                            class="el-upload-list__item-delete"
                            @click="imageHandleRemove"
                        >
                            <i class="el-icon-delete" />
                        </span>
                    </span>
                </div> -->
            </el-upload>
        </div>

        <el-form
            ref="form"
            v-loading="$wait.is('updating')"
            class="px-12"
            :model="selectedKey"
            :rules="rules"
            @submit.native.prevent
            @keyup.native.enter="updateKey"
        >
            <el-form-item :label="$t('keys.name')" prop="name">
                <el-input v-model="selectedKey.name" />
            </el-form-item>
            <el-form-item :label="$t('keys.description')">
                <el-input v-model="selectedKey.description" type="textarea" :rows="3" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button :disabled="$wait.is('updating')" @click="$emit('close')">{{ $t('common.cancel') }}</el-button>
            <el-button type="primary" :loading="$wait.is('updating')" @click="updateKey">{{ $t('common.update') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        modalEditKey: {
            type:    Boolean,
            default: false,
        },
        selectedKeyProp: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            file:        { ...this.selectedKeyProp }.image_src,
            selectedKey: { ...this.selectedKeyProp },
            rules:       {
                name: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async updateKey() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            try {
                this.$wait.start('updating');

                await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.selectedKey.uuid}`, {
                    name:        this.selectedKey.name,
                    description: this.selectedKey.description,
                });

                if ((this.file !== this.selectedKey.image_src) || (!this.file && !this.selectedKey.image_src)) {
                    await this.uploadOrRemoveImage();
                }

                this.$emit('refresh');
                this.$emit('close');
                this.$notify.success({ title: this.$t('common.success') });
            } finally {
                this.$wait.end('updating');
            }
        },

        async uploadOrRemoveImage() {
            if (this.file) {
                await this.uploadImage();
                return;
            }
            await this.removeImage();
        },

        async uploadImage() {
            try {
                const formData = new FormData();
                formData.append('file', this.file.raw);

                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.selectedKey.uuid}/upload_image`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
            } catch {
                console.log('Error while uploading image');
            }
        },

        async removeImage() {
            try {
                await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.selectedKey.uuid}/delete_image`);
            } catch {
                console.log('Error while removing image');
            }
        },

        imageHandleChange(file) {
            this.file = file;
        },

        imageHandleRemove() {
            this.$refs.upload.clearFiles();
            this.file = null;
        },

        removeExistingImage() {
            this.file = null;
            this.selectedKey.image_src = null;
        },
    },
};
</script>
<style>
    .hide-new-file .el-upload {
        display: none !important;
    }
</style>
